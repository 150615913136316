import { Box, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Logo from '../Assets/svg/Logo'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = () => {
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');
    const categoriesList = useSelector(state => state.auth.categoriesList);

    return (
        <Box
            sx={{
                mt: { md: '85px', xs: '50px' },
                pt: '62px',
                pb: isMedium ? '20px' : '44px',
                px: isSmall ? '20px' : isMedium ? '40px' : isLarge ? '80px' : '116px',
                background: '#14212A',
            }}
        >
            <Grid
                container
                spacing={isMedium ? 5 : isLarge ? 5 : 10}
            >
                <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{
                        mt: { lg: '5px', xs: 0 },
                        display: 'flex',
                        mb: isMedium ? '40px' : 0,
                    }}
                >
                    <Box
                        onClick={() => navigate('/')}
                        component={'div'}
                        sx={{
                            cursor: 'pointer',
                            '& svg': {
                                filter: 'invert(1)'
                            }
                        }}
                    >
                        <Logo />
                    </Box>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <Typography color={primary.main} fontWeight={700} textTransform={'uppercase'} whiteSpace={'nowrap'} fontSize={isMedium ? 14 : 16} fontFamily={"Nunito Sans"}>
                        Quick Links
                    </Typography>
                    <List>
                        <ListItem
                            sx={{ cursor: 'pointer', pl: 0 }}
                        >
                            <KeyboardDoubleArrowRightIcon
                                sx={{
                                    color: primary.main,
                                    fontSize: isMedium ? 20 : 24,
                                }}
                            />&nbsp;&nbsp;
                            <ListItemText
                                onClick={() => navigate('/')}
                                primary="Home"
                                sx={{
                                    color: primary.main,
                                    fontFamily: "Nunito Sans",
                                    ':hover': {
                                        textDecoration: 'underline'
                                    },
                                    'span': {
                                        fontSize: isMedium ? 14 : 16,
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem
                            sx={{
                                cursor: 'pointer',
                                pt: 0,
                                pl: 0
                            }}
                        >
                            <KeyboardDoubleArrowRightIcon
                                sx={{
                                    color: primary.main,
                                    fontSize: isMedium ? 20 : 24,
                                }}
                            />&nbsp;&nbsp;
                            <ListItemText
                                onClick={() => navigate('/#support')}
                                primary="Contact Us"
                                sx={{
                                    fontFamily: "Nunito Sans",
                                    color: primary.main,
                                    whiteSpace: 'nowrap',
                                    ':hover': {
                                        textDecoration: 'underline'
                                    },
                                    'span': {
                                        fontSize: isMedium ? 14 : 16,
                                    }
                                }}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <Typography color={primary.main} fontWeight={700} textTransform={'uppercase'} mb={'10px'} fontSize={isMedium ? 14 : 16} >
                        CATEGORIES
                    </Typography>
                    <List>
                        {categoriesList.map(x => (
                            <ListItem
                                key={x?._id}
                                sx={{ cursor: 'pointer', pl: 0, pt: 0 }}
                            >
                                <KeyboardDoubleArrowRightIcon
                                    sx={{
                                        color: primary.main,
                                        fontSize: isMedium ? 20 : 24,
                                    }}
                                />&nbsp;&nbsp;
                                <ListItemText
                                    onClick={() => navigate(`/category/${x?.Category}`)}
                                    primary={x.Category}
                                    sx={{
                                        color: primary.main,
                                        fontFamily: "Nunito Sans",
                                        whiteSpace: 'nowrap',
                                        ':hover': {
                                            textDecoration: 'underline'
                                        },
                                        'span': {
                                            fontSize: isMedium ? 14 : 16,
                                        }
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <Typography color={primary.main} fontWeight={700} textTransform={'uppercase'} fontSize={isMedium ? 14 : 16} >
                        LOCATION
                    </Typography>
                    <Typography color={primary.main} fontWeight={600} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} mt={'19px'} component={'div'} whiteSpace={'nowrap'}>
                        Office: &nbsp;<Typography fontWeight={400} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} component={'span'}>Doha-Qatar</Typography>
                    </Typography>
                    <Typography color={primary.main} fontWeight={600} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} mt={'15px'} component={'div'} whiteSpace={'nowrap'}>
                        Address: &nbsp;<Typography fontWeight={400} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} component={'span'}>D Ring Rd, Doha</Typography>
                    </Typography>
                    <Typography color={primary.main} fontWeight={600} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} mt={'15px'} component={'div'} whiteSpace={'nowrap'}>
                        Phone no: &nbsp;
                        <Link
                            to={`tel:+974-33144266`}
                            style={{
                                color: primary.main,
                                fontSize: isMedium ? 14 : 16,
                                fontWeight: 400,
                                textDecoration: 'none',
                                fontFamily: "Nunito Sans"
                            }}
                        >+974 3314 4266</Link>
                    </Typography>
                    <Typography color={primary.main} fontWeight={600} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} mt={'15px'} component={'div'} whiteSpace={'nowrap'}>
                        Email Id: &nbsp;
                        <Link
                            to={`mailto:sales@tamreen.com`}
                            style={{
                                color: primary.main,
                                fontSize: isMedium ? 14 : 16,
                                fontWeight: 400,
                                textDecoration: 'none',
                                fontFamily: "Nunito Sans"
                            }}
                        >sales@tamreen.com</Link>
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6} display={{ md: 'block', xs: 'none' }}>
                    <Typography color={primary.main} fontWeight={700} fontFamily={"Nunito Sans"} fontSize={isMedium ? 14 : 16} textTransform={'uppercase'} mb={'19px'} whiteSpace={'nowrap'}>
                        SOCIAL MEDIA
                    </Typography>
                    <Box
                        sx={{
                            color: primary.main,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <FacebookIcon />
                        <GoogleIcon />
                        <TwitterIcon />
                    </Box>
                </Grid>
            </Grid>
            <hr
                style={{
                    marginTop: '55px',
                }}
            />
            <Box
                sx={{
                    color: primary.main,
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    mt: '20px',
                    display: { md: 'none', xs: 'flex' }
                }}
            >
                <FacebookIcon />
                <GoogleIcon />
                <TwitterIcon />
            </Box>
            <Typography color={primary.main} textAlign={{ md: 'left', xs: 'center' }} mt={'10px'} fontFamily={"Nunito Sans"}>
                &copy; 2024 Tamreen. All rights reserved.
            </Typography>
        </Box>
    )
}

export default Footer