import { Box, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BookingCard from '../Components/BookingCard';
import { getPreviousBooking, getUpcommingBookings } from '../Endpoints/UserEndpoints';
import Loader from '../Components/Loader';
import Pagination from '../Components/Pagination';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const Bookings = () => {
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');

    const rowsPerPage = 9;
    const [page, setPage] = useState(0);
    const [value, setValue] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector(state => state.auth.userData);
    const [previousBookings, setPreviousBooking] = useState([]);
    const [upcomingBookings, setUpcomingBooking] = useState([]);

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
    };

    const handleChange = (_, newValue) => {
        setValue(newValue);
        setPage(0);
    };

    const getPreviousBookingsList = async () => {
        try {
            const response = await getPreviousBooking();
            setPreviousBooking(response?.data ?? []);
            setIsLoaded(true);
        } catch (e) {
            console.log("ERR", e)
        }
    }

    const getUpcomingBookingsList = async () => {
        setIsLoaded(false);
        try {
            const response = await getUpcommingBookings();
            setUpcomingBooking(response?.data ?? []);
        } catch (e) {
            console.log("ERR", e)
        }
    }

    useEffect(() => {
        getUpcomingBookingsList();
        getPreviousBookingsList();
    }, [])

    if (!user) {
        navigate('/login');
        return;
    }

    return (
        <>
            {/* BODY */}
            <Box
                sx={{
                    m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '80px' : '100px 116px',
                    flex: 1
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: '#DCF1FF' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{
                            '.MuiTabs-indicator': {
                                backgroundColor: primary.darkBlue
                            },
                            '.MuiTab-root': {
                                textTransform: 'capitalize',
                                color: '#15192C',
                                fontWeight: 700,
                                fontSize: { lg: 18, sm: 16, xs: 14 }
                            },
                            '.Mui-selected': {
                                color: `${primary.darkBlue} !important`,
                            },
                        }}
                    >
                        <Tab label="Upcoming Bookings" />
                        <Tab label="Previous bookings" />
                        <Tab label="All Bookings" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={2}>
                    {isLoaded ?
                        (upcomingBookings?.length > 0 || previousBookings?.length > 0) ?
                            <>
                                <Grid container spacing={{ md: 3, xs: 1 }}>
                                    {[...previousBookings, ...upcomingBookings]?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(x => (
                                        <Grid item lg={4} sm={6} xs={12} key={x?._id}>
                                            <BookingCard
                                                onClick={() => navigate(`/bookings/${x?._id}`, { state: x })}
                                                item={{
                                                    ...x,
                                                    image: x?.serviceClassDetails?.Images?.length > 0 ? x?.serviceClassDetails?.Images[0] : undefined,
                                                    sportName: x?.ServiceName,
                                                    facilityName: x?.facilitiesDetails?.Name ?? '',
                                                    facilityAddress: x?.facilitiesDetails?.Address ?? '',
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                {[...previousBookings, ...upcomingBookings]?.length > rowsPerPage &&
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: '30px'
                                        }}
                                    >
                                        <Pagination
                                            handleChangePage={handleChangePage}
                                            count={Math.ceil([...previousBookings, ...upcomingBookings]?.length / rowsPerPage)}
                                        />
                                    </Box>
                                }
                            </>
                            :
                            <Typography>No bookings found</Typography>
                        :
                        <Loader />
                    }
                </TabPanel>
                <TabPanel value={value} index={0}>
                    {upcomingBookings?.length > 0 ?
                        <>
                            <Grid container spacing={3}>
                                {upcomingBookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(x => (
                                    <Grid item lg={4} sm={6} xs={12} key={x?._id}>
                                        <BookingCard
                                            onClick={() => navigate(`/bookings/${x?._id}`, { state: x })}
                                            item={{
                                                ...x,
                                                image: x?.serviceClassDetails?.Images?.length > 0 ? x?.serviceClassDetails?.Images[0] : undefined,
                                                sportName: x?.ServiceName,
                                                facilityName: x?.facilitiesDetails?.Name ?? '',
                                                facilityAddress: x?.facilitiesDetails?.Address ?? '',
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {upcomingBookings?.length > rowsPerPage &&
                                <Box
                                    component={'div'}
                                    sx={{
                                        mt: '30px'
                                    }}
                                >
                                    <Pagination
                                        handleChangePage={handleChangePage}
                                        count={Math.ceil(upcomingBookings?.length / rowsPerPage)}
                                    />
                                </Box>
                            }
                        </>
                        :
                        <Typography>No upcoming bookings found</Typography>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {previousBookings?.length > 0 ?
                        <>
                            <Grid container spacing={{ lg: 3, xs: 2 }}>
                                {previousBookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.sort((a, b) => a?.BookingDate > b?.BookingDate)?.map(x => (
                                    <Grid item lg={4} sm={6} xs={12} key={x?._id}>
                                        <BookingCard
                                            hideData={true}
                                            onClick={() => navigate(`/bookings/${x?._id}`, { state: x })}
                                            item={{
                                                ...x,
                                                image: x?.serviceClassDetails?.Images?.length > 0 ? x?.serviceClassDetails?.Images[0] : undefined,
                                                sportName: x?.ServiceName,
                                                facilityName: x?.facilitiesDetails?.Name ?? '',
                                                facilityAddress: x?.facilitiesDetails?.Address ?? '',
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {previousBookings?.length > rowsPerPage &&
                                <Box
                                    component={'div'}
                                    sx={{
                                        mt: '30px'
                                    }}
                                >
                                    <Pagination
                                        handleChangePage={handleChangePage}
                                        count={Math.ceil(previousBookings?.length / rowsPerPage)}
                                    />
                                </Box>
                            }
                        </>
                        :
                        <Typography>No previous bookings found</Typography>
                    }
                </TabPanel>
            </Box>
            {/* BODY */}
        </>
    )
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )
            }
        </div >
    );
}

export default Bookings